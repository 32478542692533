const { gql } = require("@apollo/client");

const GET_APPLICATION_STATUS = gql`
	query get_status($unique_id: String!) {
		workpass_applications(
			where: { unique_id: { _eq: $unique_id } }
			limit: 1
			order_by: { id: desc }
		) {
			first_name
			last_name
			company_name
			destination_municipality
			municipality_code
			id
			origin_municipality
			status
			notes
			unique_id
			workpass_application_files(where: { type: { _eq: "picture" } }) {
				id
				url
			}
		}
	}
`;

const FOR_APPROVAL = gql`
	query for_approval($offset: Int, $municipality_code: String) {
		workpass_applications(
			where: {
				status: { _eq: "Pending" }
				municipality_code: { _eq: $municipality_code }
			}
			offset: $offset
			limit: 5
			order_by: { id: desc }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: { status: { _eq: "Pending" } }) {
			aggregate {
				count
			}
		}
	}
`;

const APPROVED = gql`
	query for_approval($offset: Int, $municipality_code: String) {
		workpass_applications(
			where: {
				status: { _eq: "Approved" }
				municipality_code: { _eq: $municipality_code }
			}
			offset: $offset
			limit: 5
			order_by: { id: desc }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: { status: { _eq: "Approved" } }) {
			aggregate {
				count
			}
		}
	}
`;

const DENIED = gql`
	query for_approval($offset: Int, $municipality_code: String) {
		workpass_applications(
			where: {
				status: { _eq: "Denied" }
				municipality_code: { _eq: $municipality_code }
			}
			offset: $offset
			limit: 5
			order_by: { id: desc }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: { status: { _eq: "Denied" } }) {
			aggregate {
				count
			}
		}
	}
`;

const GET_QR = gql`
	query qr_code($unique_id: String) {
		mobile_app_users(where: { unique_installation_id: { _eq: $unique_id } }) {
			id
			unique_installation_id
		}
	}
`;

export { GET_APPLICATION_STATUS, FOR_APPROVAL, APPROVED, DENIED, GET_QR };
