import React from "react";
import { GlobalContext } from "../../helpers/global-context";
import HomepageView from "./homepage-view";

const HomepageController = () => {
	const values = {};

	return (
		<GlobalContext.Provider value={values}>
			<HomepageView />
		</GlobalContext.Provider>
	);
};

export default HomepageController;
