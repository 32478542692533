import React from "react";
import { Layout, Menu } from "antd";
import { CloseOutlined, UserOutlined, CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Content, Footer, Sider } = Layout;

const DashboardTemplate = (Component) => {
	return class extends React.Component {
		render() {
			return (
				<Layout>
					<Sider
						breakpoint="lg"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
						style={{ backgroundColor: "white" }}
					>
						<div style={{ margin: "10px 0" }}>
							<img
								src={require("../assets/app-logo.png")}
								style={{ width: "100%" }}
								alt="WeTrace App"
							/>
						</div>
						<Menu mode="inline" defaultSelectedKeys={["4"]}>
							<Menu.Item key="1" icon={<UserOutlined />}>
								<Link to="/lgu-for-approval">For Approval</Link>
							</Menu.Item>
							<Menu.Item key="2" icon={<CheckOutlined />}>
								<Link to="/lgu-approved">Approved</Link>
							</Menu.Item>
							<Menu.Item key="3" icon={<CloseOutlined />}>
								<Link to="/lgu-denied">Denied</Link>
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout>
						<Content style={{ margin: "24px 16px 0" }}>
							<div
								style={{
									padding: 24,
									minHeight: 500,
									backgroundColor: "white",
								}}
							>
								<Component />
							</div>
						</Content>
						<Footer style={{ textAlign: "center" }}>
							&copy;2020 WeTrace App
						</Footer>
					</Layout>
				</Layout>
			);
		}
	};
};

export default DashboardTemplate;
