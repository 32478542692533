import React, { useContext } from "react";
import DataTable from "../../components/table";
import { GlobalContext } from "../../../helpers/global-context";
import { PageHeader } from "antd";

const DeniedView = () => {
	const { dataSource } = useContext(GlobalContext);

	return (
		<>
			<PageHeader title="Denied Applications" />
			<DataTable dataSource={dataSource} />
		</>
	);
};

export default DeniedView;
