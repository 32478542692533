import React, { useContext } from "react";
import { Table, Descriptions, Pagination } from "antd";
import { GlobalContext } from "../../helpers/global-context";
import TableActions from "./table-actions";

const DataTable = (props) => {
	const { _offsetHandler, total } = useContext(GlobalContext);
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Company",
			dataIndex: "company",
			key: "company",
			width: 200,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Name">{val.company_name}</Descriptions.Item>
					<Descriptions.Item label="Office Number">
						{val.office_number}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Origin",
			key: "origin",
			dataIndex: "origin",
			width: 250,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Province">
						{val.origin_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.origin_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.origin_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.origin_location}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Destination",
			dataIndex: "destination",
			key: "destination",
			width: 250,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Province">
						{val.destination_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.destination_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.destination_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.destination_location}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Municipality Code",
			key: "municipality_code",
			dataIndex: "municipality_code",
		},
		{
			title: "Date Requested",
			key: "request_date",
			dataIndex: "request_date",
		},
		{
			title: "Actions",
			key: "actions",
			width: 200,
			render: (val) => <TableActions val={val} />,
		},
	];

	return (
		<>
			<Table columns={columns} {...props} pagination={false} />
			<div style={{ marginTop: 20 }}>
				<Pagination
					defaultCurrent={1}
					total={total}
					pageSize={5}
					onChange={_offsetHandler}
				/>
			</div>
		</>
	);
};

export default DataTable;
