import React from "react";
import { Layout, Menu, Button } from "antd";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

const BaseTemplate = (Component) => {
	return class extends React.Component {
		render() {
			return <Component />;
		}
	};
};

export default BaseTemplate;
