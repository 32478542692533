import React, { useContext } from "react";
import { AppContext } from "../helpers/global-context";
import { Redirect, Route } from "react-router-dom";
import { withCookies } from "react-cookie";

const Authenticated = (props) => {
	const { cookies } = useContext(AppContext);

	return cookies.token ? <Route {...props} /> : <Redirect to="/lgu-login" />;
};

export default withCookies(Authenticated);
