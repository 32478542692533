export const VALIDATION = {
	FIRSTNAME: [{ required: true, message: "Please input your first name!" }],
	LASTNAME: [{ required: true, message: "Please input your last name!" }],
	MOBILE_NUMBER: [
		{ required: true, message: "Please input your mobile number!" },
	],
	COMPANY_NAME: [
		{ required: true, message: "Please input your company name!" },
	],
	OFFICE_NUMBER: [
		{ required: true, message: "Please input your company office number!" },
	],
	ORIGIN_PROVINCE: [
		{ required: true, message: "Please select your province of origin" },
	],
	ORIGIN_MUNICIPALITY: [
		{ required: true, message: "Please select your municipality of origin" },
	],
	ORIGIN_BARANGAY: [
		{ required: true, message: "Please select your barangay of origin" },
	],
	ORIGIN_ADDRESS: [
		{ required: true, message: "Please input your origin address" },
	],
	DESTINATION_PROVINCE: [
		{ required: true, message: "Please select your province of destination" },
	],
	DESTINATION_MUNICIPALITY: [
		{
			required: true,
			message: "Please select your municipality of destination",
		},
	],
	DESTINATION_BARANGAY: [
		{ required: true, message: "Please select your barangay of destination" },
	],
	DESTINATION_ADDRESS: [
		{ required: true, message: "Please input your destination address" },
	],
	QR: [
		{
			required: true,
			message:
				"Please scan your QR code found in WeTrace App (WeTrace -> Device Information)",
		},
	],
	USERNAME: [{ required: true, message: "Username is required" }],
	PASSWORD: [{ required: true, message: "Password is required" }],
};
