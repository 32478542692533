import React, { useContext } from "react";
import { Row, Col, Card, Button } from "antd";
import { Link } from "react-router-dom";
import BaseTemplate from "../../template/base";
import Modal from "antd/lib/modal/Modal";
import { GlobalContext } from "../../helpers/global-context";

const HomepageView = () => {
	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col xl={6} lg={4} xs={1} />
				<Col xl={12} lg={16} xs={22} style={{ marginTop: 50 }}>
					<div className="space-align-block">
						<Card
							// style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.4)" }}
							style={{ borderWidth: 0 }}
						>
							<Row>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22}>
									<img
										src={require("../../assets/app-logo.png")}
										style={{ width: "100%" }}
										alt="WeTrace App"
									/>
								</Col>
								<Col lg={6} sm={1}></Col>
							</Row>
							<h1 style={{ textAlign: "center" }}>WORK PASS APPLICATION</h1>
							<h3 style={{ textAlign: "center" }}>
								version {process.env.REACT_APP_VERSION}
							</h3>
							<div style={{ marginTop: 30, marginBottom: 50 }}>
								<Row gutter={16}>
									<Col xl={4} lg={1} md={0} sm={0} xs={0} />
									<Col xl={8} lg={11} md={12} sm={12} xs={24}>
										<Button
											size="large"
											block
											style={{
												borderColor: "#2BBB94",
												backgroundColor: "#2BBB94",
												color: "#ffffff",
												height: 70,
												fontSize: 14,
												marginTop: 20,
												letterSpacing: 2,
											}}
											shape="round"
										>
											<Link to="/work-pass">CLICK HERE TO APPLY</Link>
										</Button>
									</Col>
									<Col xl={8} lg={11} md={12} sm={12} xs={24}>
										<Button
											size="large"
											block
											style={{
												borderColor: "#2BBB94",
												backgroundColor: "#2BBB94",
												color: "#ffffff",
												height: 70,
												fontSize: 14,
												marginTop: 20,
												letterSpacing: 2,
											}}
											shape="round"
										>
											<Link to="/work-pass-status">CHECK STATUS</Link>
										</Button>
									</Col>
								</Row>
							</div>
							<div style={{ paddingBottom: 50 }}>
								<h3 style={{ textAlign: "center", marginBottom: 20 }}>
									Download WeTrace App
								</h3>
								<Row gutter={16}>
									<Col xl={3} lg={0} md={0} sm={0} xs={0}></Col>
									<Col xl={6} lg={8} md={8} sm={8} xs={8}>
										<div
											onClick={() => {
												window.location.href =
													"https://play.google.com/store/apps/details?id=com.dxform.ph";
											}}
										>
											<img
												src={require("../../assets/google.jpg")}
												style={{ width: "100%" }}
												alt="Play Store"
											/>
										</div>
									</Col>
									<Col xl={6} lg={8} md={8} sm={8} xs={8}>
										<div
											onClick={() => {
												window.location.href =
													"https://apps.apple.com/ph/app/wetrace-ph/id1506567245";
											}}
										>
											<img
												src={require("../../assets/apple.jpg")}
												style={{ width: "100%" }}
												alt="App Store"
											/>
										</div>
									</Col>
									<Col xl={6} lg={8} md={8} sm={8} xs={8}>
										<div
											onClick={() => {
												window.location.href =
													"https://appgallery1.huawei.com/#/app/C102238713";
											}}
										>
											<img
												src={require("../../assets/huawei.jpg")}
												style={{ width: "100%" }}
												alt="App Store"
											/>
										</div>
									</Col>
								</Row>
							</div>
						</Card>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default BaseTemplate(HomepageView);
