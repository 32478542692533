import React, { useContext } from "react";
import { Form, Input, Divider, Button, Modal, Alert, Upload } from "antd";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";
import QrReader from "react-qr-reader";
import { InboxOutlined } from "@ant-design/icons";

const Step1 = () => {
	const {
		form,
		_onFinishHandler,
		_onErrorHandler,
		state,
		_qrHandler,
		showQR,
		_scanHandler,
		_beforeUpload,
		qr_error,
	} = useContext(GlobalContext);

	return (
		<Form
			layout="vertical"
			onFinish={_onFinishHandler}
			onFinishFailed={_onErrorHandler}
			form={form}
		>
			<Divider orientation="left">Personal & Company Information</Divider>
			<Form.Item
				name="first_name"
				label={<h3>First name</h3>}
				rules={VALIDATION.FIRSTNAME}
				initialValue={state.first_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="last_name"
				label={<h3>Last name</h3>}
				rules={VALIDATION.LASTNAME}
				initialValue={state.last_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="mobile_number"
				label={<h3>Mobile/Contact Number</h3>}
				rules={VALIDATION.MOBILE_NUMBER}
				initialValue={state.mobile_number}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="company_name"
				label={<h3>Company name</h3>}
				rules={VALIDATION.COMPANY_NAME}
				initialValue={state.company_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="office_number"
				label={<h3>Company contact number</h3>}
				rules={VALIDATION.OFFICE_NUMBER}
				initialValue={state.office_number}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="unique_id"
				label={
					<h3>
						QR code{" "}
						<small>
							(QR code can be found in WeTrace App -> Device Information)
						</small>
					</h3>
				}
				rules={VALIDATION.QR}
			>
				<Input type="hidden" size="large" enterButton="SCAN QR CODE" />
				<div style={{ textAlign: "center", marginBottom: 10 }}>
					<Button
						type="primary"
						size="large"
						onClick={_qrHandler}
						style={{ marginRight: 5 }}
					>
						SCAN QR
					</Button>
					<Upload
						beforeUpload={_beforeUpload}
						multiple={false}
						showUploadList={false}
						// onRemove={_removeImage}
					>
						<Button type="primary" size="large">
							UPLOAD QR
						</Button>
					</Upload>
				</div>
			</Form.Item>

			<Alert
				type="info"
				description="Please take a clear picture or screenshot of the QR Code when using upload."
				style={{ marginBottom: 10 }}
			/>
			{form.getFieldValue("unique_id") && (
				<div style={{ margin: "10px 0" }}>
					<Alert type="success" description="QR CODE complete!" />
				</div>
			)}
			{qr_error && (
				<div style={{ margin: "10px 0" }}>
					<Alert type="error" description="QR Code Invalid." />
				</div>
			)}
			<Modal visible={showQR} footer={null} onCancel={_qrHandler}>
				<QrReader
					delay={300}
					style={{ width: "100%" }}
					onScan={_scanHandler}
					onError={_onErrorHandler}
				/>
			</Modal>
			<div>
				<Button type="primary" htmlType="submit" block size="large">
					NEXT
				</Button>
			</div>
		</Form>
	);
};

export default Step1;
