import React, { useContext } from "react";
import { Input, Select, Divider, Button, Form, Row, Col } from "antd";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";

const Step3 = () => {
	const {
		_destinationProvinceChangeHandler,
		_destinationMunicipalityChangeHandler,
		_onErrorHandler,
		_backHandler,
		provinces,
		destinationMunicipalities,
		destinationBarangays,
		_onThirdStepHandler,
		form,
	} = useContext(GlobalContext);

	return (
		<Form
			layout="vertical"
			onFinish={_onThirdStepHandler}
			onFinishFailed={_onErrorHandler}
			form={form}
		>
			<Divider orientation="left">Destination</Divider>
			<Form.Item
				name="destination_province"
				label={<h3>Province</h3>}
				rules={VALIDATION.DESTINATION_PROVINCE}
			>
				<Select
					placeholder="Select Provincce"
					size="large"
					onChange={_destinationProvinceChangeHandler}
					showSearch={true}
				>
					{provinces.map((province, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={province.province_description}
							>
								{province.province_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="destination_municipality"
				label={<h3>Municipality</h3>}
				rules={VALIDATION.DESTINATION_MUNICIPALITY}
			>
				<Select
					placeholder="Select Municipality"
					size="large"
					onChange={_destinationMunicipalityChangeHandler}
					showSearch={true}
				>
					{destinationMunicipalities.map((municipality, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={municipality.city_municipality_description}
							>
								{municipality.city_municipality_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="destination_barangay"
				label={<h3>Barangay</h3>}
				rules={VALIDATION.DESTINATION_BARANGAY}
			>
				<Select
					placeholder="Select Barangay"
					size="large"
					showSearch={true}
					// onChange={_provinceChangeHandler}
				>
					{destinationBarangays.map((barangay, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={barangay.barangay_description}
							>
								{barangay.barangay_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="destination_location"
				label={<h3>Address</h3>}
				rules={VALIDATION.DESTINATION_ADDRESS}
			>
				<Input size="large" />
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					<Button
						type="primary"
						htmlType="button"
						block
						size="large"
						onClick={() => _backHandler("step_2")}
						ghost
					>
						BACK
					</Button>
				</Col>
				<Col span={12}>
					<Button type="primary" htmlType="submit" block size="large">
						NEXT
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default Step3;
