import React, { useContext, useState } from "react";
import { GlobalContext } from "../../helpers/global-context";
import { Space, Popconfirm, message, Descriptions, Divider } from "antd";
import Modal from "antd/lib/modal/Modal";

const TableActions = ({ val }) => {
	const { _approveHandler, _denyHandler, _viewHandler } = useContext(
		GlobalContext
	);
	const [visible, setVisible] = useState(false);
	const [file, setFile] = useState(null);
	const [showFile, setShowFile] = useState(null);

	const _onCancel = () => {
		message.error("Action cancelled");
	};

	const _pictureHandler = (url) => {
		setShowFile(true);
		setFile(url);
	};

	return (
		<div>
			<Modal
				visible={showFile}
				onCancel={() => setShowFile(false)}
				closable
				footer={null}
				width={1000}
			>
				<img
					src={file}
					style={{ width: "100%", objectFit: "cover" }}
					alt="picture"
				/>
			</Modal>
			<Modal
				visible={visible}
				onCancel={() => setVisible(false)}
				footer={null}
				closable
			>
				<div style={{ textAlign: "center" }}>
					<img
						src={val.picture}
						style={{ width: 150, height: 150, objectFit: "cover" }}
						alt="Profile Picture"
					/>
				</div>
				<Divider orientation="center">Personal & Company Information</Divider>
				<Descriptions column={2}>
					<Descriptions.Item label="Name">{val.name}</Descriptions.Item>
					<Descriptions.Item label="Company name">
						{val.company.company_name}
					</Descriptions.Item>
					<Descriptions.Item label="Company number">
						{val.company.office_number}
					</Descriptions.Item>
					<Descriptions.Item label="Application Status">
						{val.status}
					</Descriptions.Item>
				</Descriptions>
				<Divider orientation="center">Origin</Divider>
				<Descriptions column={2}>
					<Descriptions.Item label="Province">
						{val.origin.origin_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.origin.origin_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.origin.origin_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.origin.origin_location}
					</Descriptions.Item>
				</Descriptions>
				<Divider orientation="center">Destination</Divider>
				<Descriptions column={2}>
					<Descriptions.Item label="Province">
						{val.destination.destination_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.destination.destination_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.destination.destination_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.destination.destination_location}
					</Descriptions.Item>
				</Descriptions>
				<Divider orientation="center">Files</Divider>
				<h4>Medical Certificates</h4>
				{val.workpass_application_files
					.filter(({ type }) => type === "medical_certificate")
					.map(({ url }) => (
						<a
							href="#"
							onClick={_pictureHandler.bind(this, url)}
							style={{ marginRight: 5 }}
						>
							<img
								src={url}
								style={{ width: 70, height: 70, objectFit: "cover" }}
								alt="Medical Certificate"
							/>
						</a>
					))}
				<h4>Company Identification Card(s)</h4>
				{val.workpass_application_files
					.filter(({ type }) => type === "company_id")
					.map(({ url }) => (
						<a
							href="#"
							onClick={_pictureHandler.bind(this, url)}
							style={{ marginRight: 5 }}
						>
							<img
								src={url}
								style={{ width: 70, height: 70, objectFit: "cover" }}
								alt="Medical Certificate"
							/>
						</a>
					))}
				<h4>Certificate of Employment</h4>
				{val.workpass_application_files
					.filter(({ type }) => type === "certificate_of_employment")
					.map(({ url }) => (
						<a
							href="#"
							onClick={_pictureHandler.bind(this, url)}
							style={{ marginRight: 5 }}
						>
							<img
								src={url}
								style={{ width: 70, height: 70, objectFit: "cover" }}
								alt="Medical Certificate"
							/>
						</a>
					))}
			</Modal>
			{val.status === "Pending" ? (
				<Space size="middle">
					<Popconfirm
						onCancel={_onCancel.bind(this)}
						onConfirm={_approveHandler.bind(this, val)}
					>
						<a href="#">Approve</a>
					</Popconfirm>
					<Popconfirm
						onCancel={_onCancel.bind(this)}
						onConfirm={_denyHandler.bind(this, val)}
					>
						<a href="#">Deny</a>
					</Popconfirm>
					<a href="#" onClick={() => setVisible(true)}>
						View
					</a>
				</Space>
			) : val.status === "Approved" ? (
				<Space size="middle">
					<Popconfirm
						onCancel={_onCancel.bind(this)}
						onConfirm={_denyHandler.bind(this, val)}
					>
						<a href="#">Deny</a>
					</Popconfirm>
					<a href="#" onClick={() => setVisible(true)}>
						View
					</a>
				</Space>
			) : (
				<Space size="middle">
					<Popconfirm
						onCancel={_onCancel.bind(this)}
						onConfirm={_approveHandler.bind(this, val)}
					>
						<a href="#">Approve</a>
					</Popconfirm>
					<a href="#" onClick={() => setVisible(true)}>
						View
					</a>
				</Space>
			)}
		</div>
	);
};

export default TableActions;
