import React, { useContext } from "react";
import DataTable from "../../components/table";
import { GlobalContext } from "../../../helpers/global-context";
import { PageHeader } from "antd";

const ForApprovalView = () => {
	const { dataSource } = useContext(GlobalContext);

	return (
		<>
			<PageHeader title="For Approval Applications" />
			<DataTable dataSource={dataSource} />
		</>
	);
};

export default ForApprovalView;
