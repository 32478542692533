import React, { useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../helpers/global-context";
import StatusView from "./statuschecker-view";
import { useLazyQuery } from "@apollo/client";
import { GET_APPLICATION_STATUS } from "../../graphql/queries";
import htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { useReactToPrint } from "react-to-print";
import QrCodeReader from "qrcode-reader";
import { Decoder } from "@nuintun/qrcode";

const BlobToImageData = (blob) => {
	let blobUrl = URL.createObjectURL(blob);

	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve(img);
		img.onerror = (err) => reject(err);
		img.src = blobUrl;
	}).then((img) => {
		URL.revokeObjectURL(blobUrl);
		// Limit to 256x256px while preserving aspect ratio
		let [w, h] = [img.width, img.height];
		let aspectRatio = w / h;
		// Say the file is 1920x1080
		// divide max(w,h) by 256 to get factor
		let factor = Math.max(w, h) / 256;
		w = w / factor;
		h = h / factor;

		// REMINDER
		// 256x256 = 65536 pixels with 4 channels (RGBA) = 262144 data points for each image
		// Data is encoded as Uint8ClampedArray with BYTES_PER_ELEMENT = 1
		// So each images = 262144bytes
		// 1000 images = 260Mb
		let canvas = document.createElement("canvas");
		canvas.width = w;
		canvas.height = h;
		let ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);

		return ctx.getImageData(0, 0, w, h); // some browsers synchronously decode image here
	});
};

const StatusController = () => {
	const [status, setStatus] = useState(null);
	const [notes, setNotes] = useState(null);
	const [info, setInfo] = useState(null);
	const [invalid, setInvalid] = useState(false);
	const [image, setImage] = useState(null);
	const printRef = useRef();

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			// reader.readAsDataURL(file);
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const [getApplicationStatus, { data }] = useLazyQuery(
		GET_APPLICATION_STATUS,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		if (data) {
			if (data.workpass_applications?.length) {
				const status = data.workpass_applications?.[0]?.status;
				const notes = data.workpass_applications?.[0]?.notes;
				setStatus(status);
				setNotes(notes);
				if (status === "Approved") {
					setInfo(data.workpass_applications?.[0]);
				}
			} else {
				setStatus("Invalid");
			}
		}
	}, [data]);

	const _scanHandler = (data) => {
		if (data) {
			if (data === "ea60bd73-9bc8-46ab-a60f-9b734f1dab7e") {
				setStatus("Invalid");
			} else {
				getApplicationStatus({
					variables: {
						unique_id: data,
					},
				});
			}
		}
	};

	const _scanErrorHandler = (err) => {
		console.log(err);
	};

	const _clickHandler = () => {
		htmlToImage.toBlob(document.getElementById("card")).then(function (blob) {
			window.saveAs(blob, "id.png");
		});
	};

	const _printHandler = useReactToPrint({
		content: () => printRef.current,
	});

	const _onErrorHandler = (err) => {
		console.log(err);
	};

	const _rescanHandler = () => {
		setStatus(null);
	};

	const _beforeUpload = (file) => {
		toBase64(file).then((res) => {
			const qr = new Decoder();
			qr.scan(res)
				.then((res) => {
					if (res.data === "ea60bd73-9bc8-46ab-a60f-9b734f1dab7e") {
						setStatus("Invalid");
					} else {
						getApplicationStatus({
							variables: {
								unique_id: res.data,
							},
						});
					}
				})
				.catch((err) => {
					setStatus("Invalid");
				});
		});
	};

	const values = {
		_scanHandler,
		_scanErrorHandler,
		_clickHandler,
		_printHandler,
		_onErrorHandler,
		_rescanHandler,
		printRef,
		status,
		info,
		invalid,
		notes,
		image,
		_beforeUpload,
	};

	return (
		<GlobalContext.Provider value={values}>
			<StatusView />
		</GlobalContext.Provider>
	);
};

export default StatusController;
