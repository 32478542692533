import React, { useContext } from "react";
import { Row, Col, Card, Result, Button, Upload, Divider, Alert } from "antd";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import { GlobalContext } from "../../helpers/global-context";
import ReactToPdf from "react-to-pdf";
import { InboxOutlined } from "@ant-design/icons";
import Identification from "./identication";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFDownloadLink,
	Image,
} from "@react-pdf/renderer";

const PDFDoc = ({ info }) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View
					style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
				>
					<View style={{ width: 435, height: 600, position: "relative" }}>
						<Image
							src={require("../../assets/template.jpg")}
							style={{ width: "100%", height: "100%" }}
						/>
						<View
							style={{
								position: "absolute",
								width: 130,
								height: 130,
								objectFit: "contain",
								backgroundColor: "white",
								top: 255,
								left: 9,
							}}
						>
							<Image
								src={{ uri: info?.workpass_application_files[0].url }}
								style={{ width: 130, height: 130 }}
							/>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

const StatusView = () => {
	const {
		_onErrorHandler,
		_scanHandler,
		status,
		info,
		image,
		notes,
		_rescanHandler,
		_clickHandler,
		_printHandler,
		printRef,
		_beforeUpload,
	} = useContext(GlobalContext);

	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={6} xs={1}></Col>
				<Col lg={12} xs={22} style={{ marginTop: 50 }}>
					<div className="space-align-block">
						<Card
							style={{
								boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.4)",
								marginBottom: 50,
								paddingBottom: 50,
							}}
						>
							<Row>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22}>
									<Link to="/">
										<img
											src={require("../../assets/app-logo.png")}
											style={{ width: "100%" }}
											alt="WeTrace App"
										/>
									</Link>
								</Col>
								<Col lg={6} sm={1}></Col>
							</Row>
							<h1 style={{ textAlign: "center" }}>APPLICATION STATUS</h1>
							<h3 style={{ textAlign: "center" }}>
								version {process.env.REACT_APP_VERSION}
							</h3>
							{!status && (
								<Row gutter={16}>
									<Col lg={8} sm={4} xs={0}></Col>
									<Col lg={8} sm={16} xs={24}>
										<QrReader
											delay={300}
											style={{ width: "100%" }}
											onScan={_scanHandler}
											onError={_onErrorHandler}
										/>
										<h4 style={{ textAlign: "center", marginTop: 10 }}>
											SCAN QR CODE
										</h4>
										<Divider>OR</Divider>
										<Alert
											type="info"
											description="Please take a clear picture or screenshot of the QR Code when using upload."
											style={{ marginBottom: 10 }}
										/>
										<div style={{ height: 130 }}>
											<Upload.Dragger
												beforeUpload={_beforeUpload}
												multiple={false}
												// onRemove={_removeImage}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>
												<p className="ant-upload-text">
													Click or drag to upload QR CODE
												</p>
											</Upload.Dragger>
										</div>
									</Col>
								</Row>
							)}
							<Row gutter={16}>
								<Col lg={2} md={2} sm={0} xs={0}></Col>
								<Col lg={20} md={2} sm={24} xs={24}>
									{status && (
										<div>
											<div style={{ textAlign: "center" }}>
												<Button
													type="primary"
													size="large"
													onClick={_rescanHandler}
												>
													RESCAN / UPLOAD QR CODE
												</Button>
											</div>
											{status === "Pending" ? (
												<Result
													status="info"
													title="PENDING"
													subTitle=" Application is Pending, coordinate with your local LGU via phone or online."
												/>
											) : status === "Approved" ? (
												<Result
													status="success"
													title="APPROVED"
													subTitle="Application status was APPROVED"
												/>
											) : status === "Denied" ? (
												<Result
													status="error"
													title="DENIED"
													subTitle={`${notes}. For denied applications, you can apply again but make sure all submitted documents are correct. You can also coordinate with your LGU via phone or their social media channels.`}
												/>
											) : (
												<Result
													status="error"
													title="INVALID"
													subTitle="QR Code Invalid. Please scan the QR code you got after application submission for Web Application and iPhone, or WeTrace QR Code for Android Phones."
												/>
											)}
										</div>
									)}
								</Col>
							</Row>
							{status === "Approved" && (
								<div>
									<Identification info={info} ref={printRef} />
									<div
										style={{
											display: "block",
											marginTop: 50,
											textAlign: "center",
										}}
									>
										<Button
											type="primary"
											size="large"
											onClick={_printHandler.bind(this)}
											style={{ marginLeft: 5 }}
										>
											PRINT ID
										</Button>
									</div>
								</div>
							)}
						</Card>
					</div>
				</Col>
				<Col lg={6} xs={1}></Col>
			</Row>
		</div>
	);
};

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
	},
});

export default StatusView;
