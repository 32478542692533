const { gql } = require("@apollo/client");

const INSERT_APPLICATION = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
	) {
		insert_workpass_applications(objects: $data) {
			returning {
				id
			}
		}
	}
`;

const INSERT_USER = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
		$unique_id: String
	) {
		insert_mobile_app_users(objects: { unique_installation_id: $unique_id }) {
			returning {
				id
			}
		}

		insert_workpass_applications(objects: $data) {
			returning {
				id
				unique_id
			}
		}
	}
`;

const LOGIN_USER = gql`
	mutation map_login($username: String!, $password: String!) {
		map_login(object: { username: $username, password: $password }) {
			token
		}
	}
`;

const UPDATE_APPLICATION_STATUS = gql`
	mutation update_application_status($id: Int, $status: String) {
		update_workpass_applications(
			where: { id: { _eq: $id } }
			_set: { status: $status }
		) {
			affected_rows
			returning {
				status
			}
		}
	}
`;

export {
	INSERT_APPLICATION,
	LOGIN_USER,
	UPDATE_APPLICATION_STATUS,
	INSERT_USER,
};
