import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "apollo-link-ws";

const makeApolloClient = (token) => {
	// create an apollo link instance, a network interface for apollo client
	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_HASURA_URL_HTTP,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	// const wsLink = new WebSocketLink({
	// 	uri: process.env.REACT_APP_HASURA_URL_WS,
	// 	options: {
	// 		reconnect: true,
	// 		connectionParams: {
	// 			headers: {
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 		},
	// 	},
	// });

	// const link = split(
	// 	// split based on operation type
	// 	({ query }) => {
	// 		const { kind, operation } = getMainDefinition(query);
	// 		return kind === "OperationDefinition" && operation === "subscription";
	// 	},
	// 	wsLink,
	// 	httpLink
	// );

	// create an inmemory cache instance for caching graphql data
	const cache = new InMemoryCache();

	// instantiate apollo client with apollo link instance and cache instance
	const client = new ApolloClient({
		link: httpLink,
		cache,
		onError: (e) => {
			console.log("graphQLErrors", e);
		},
	});

	return client;
};
export default makeApolloClient;
