import React, { useEffect, useState, useRef } from "react";
import { GlobalContext } from "../../helpers/global-context";
import RegistrationpageView from "./registrationpage-view";
import * as locations from "../../helpers/locations.json";
import { initialState, initialSteps } from "../../helpers/initial-state";
import S3 from "react-aws-s3";
import { Form } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { INSERT_APPLICATION, INSERT_USER } from "../../graphql/muattions";
import Resizer from "react-image-file-resizer";
import { GET_QR } from "../../graphql/queries";
import { Decoder } from "@nuintun/qrcode";

const RegistrationpageController = () => {
	const [form] = Form.useForm();
	const [state, setState] = useState(initialState);
	const [steps, setSteps] = useState({ ...initialSteps, step_1: true });
	const [provinces, setProvinces] = useState([]);
	const [qr_success, setQRSuccess] = useState(false);
	const [municipalities, setMunicipalites] = useState([]);
	const [barangays, setBarangays] = useState([]);
	const [destinationProvinces, setDestinationProvinces] = useState([]);
	const [destinationMunicipalities, setDestinationMunicipalities] = useState(
		[]
	);
	const [generated, setGenerated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [destinationBarangays, setDestinationBarangays] = useState([]);
	const [certificates, setCertificates] = useState([]);
	const [ids, setIds] = useState([]);
	const [employment_certificates, setEmploymentCerticates] = useState([]);
	const [pictures, setPictures] = useState([]);
	const [qr_error, setQRError] = useState(null);
	const [files, setFiles] = useState([]);
	const [error, setError] = useState(false);
	const [showQR, setShowQR] = useState(false);
	const [showCamera, setShowCamera] = useState(false);
	const [picture, setPicture] = useState(null);
	const [qr_code, setQRCode] = useState(null);
	const config = {
		bucketName: process.env.REACT_APP_BUCKET_NAME,
		dirName: process.env.REACT_APP_DIR_NAME /* optional */,
		region: process.env.REACT_APP_REGION,
		accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
		s3Url: process.env.REACT_APP_S3_URL,
	};
	const [success, setSuccess] = useState(false);
	const S3Client = new S3(config);
	const webcamRef = useRef();
	const [getQR, { data: app_user }] = useLazyQuery(GET_QR, {
		fetchPolicy: "no-cache",
	});
	const [insertApplication] = useMutation(INSERT_APPLICATION, {
		onCompleted(res) {
			setQRCode(form.getFieldValue("unique_id"));
			setSuccess(true);
			setLoading(false);
		},
		onerror(err) {
			console.log(err);
		},
	});

	const [insertUser] = useMutation(INSERT_USER, {
		onCompleted(res) {
			setQRCode(form.getFieldValue("unique_id"));
			setSuccess(true);
			setLoading(false);
		},
		onerror(err) {
			console.log(err);
		},
	});

	useEffect(() => {
		let { philippine_regions } = locations.data;
		let { philippine_provinces = [] } = philippine_regions.filter(
			({ region_code }) => region_code === "07"
		)?.[0];
		setProvinces(philippine_provinces);
		setDestinationProvinces(philippine_provinces);
	}, [setProvinces, setDestinationProvinces]);

	const _onFinishHandler = (data) => {
		setState({ ...state, ...data });
		setSteps({ ...initialSteps, step_2: true });
		// form.setFieldsValue({ ...data });
	};

	const _onSecondStepHandler = (data) => {
		setState({ ...state, ...data });
		// form.setFieldsValue({ ...state, ...data });
		setSteps({ ...initialSteps, step_3: true });
	};

	const _onThirdStepHandler = (data) => {
		setState({ ...state, ...data });
		setSteps({ ...initialSteps, step_4: true });
		// form.setFieldsValue({ ...state, ...data });
	};

	const _generateQRHandler = () => {
		const unique_id = `${getRandomStrings(8)}-${getRandomStrings(
			4
		)}-${getRandomStrings(4)}-${getRandomStrings(4)}-${getRandomStrings(12)}`;
		// form.setFieldsValue({ unique_id });
		// console.log(unique_id);
		// setGenerated(true);
		return unique_id;
	};

	const _onForthStepHandler = async (data) => {
		console.log("called?");
		setLoading(true);
		const items = files.map(async ({ file, type }) => {
			let source;
			await S3Client.uploadFile(file)
				.then((data) => {
					source = {
						url: data.location,
						type,
					};
				})
				.catch((err) => console.error(err, "error"));
			return source;
		});

		Promise.all(items).then((res) => {
			const data = { ...state, workpass_application_files: { data: [...res] } };
			if (generated) {
				insertUser({
					variables: {
						data,
						unique_id: data.unique_id,
					},
				});
			} else {
				insertApplication({
					variables: {
						data,
					},
				});
			}
		});
	};

	const _onErrorHandler = (data) => {
		console.log(data);
	};

	const _provinceChangeHandler = (province) => {
		const { philippine_cities = [] } = provinces.filter(
			({ province_description }) => province_description === province
		)?.[0];
		setMunicipalites(philippine_cities);
		form.setFieldsValue({ origin_municipality: null, origin_barangay: null });
	};

	const _municipalityChangeHandler = (municipality, key) => {
		const {
			philippine_barangays = [],
			city_municipality_code = null,
		} = municipalities.filter(
			({ city_municipality_description }) =>
				city_municipality_description === municipality
		)?.[0];
		setState({ ...state, municipality_code: city_municipality_code });
		setBarangays(philippine_barangays);
	};

	const _destinationProvinceChangeHandler = (province) => {
		const { philippine_cities = [] } = destinationProvinces.filter(
			({ province_description }) => province_description === province
		)?.[0];
		setDestinationMunicipalities(philippine_cities);
		form.setFieldsValue({
			destination_municipality: null,
			destination_barangay: null,
		});
	};

	const _destinationMunicipalityChangeHandler = (municipality) => {
		const { philippine_barangays = [] } = destinationMunicipalities.filter(
			({ city_municipality_description }) =>
				city_municipality_description === municipality
		)?.[0];
		setDestinationBarangays(philippine_barangays);
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	useEffect(() => {
		if (app_user) {
			if (app_user.mobile_app_users.length) {
				const { unique_installation_id } = app_user.mobile_app_users[0];
				form.setFieldsValue({ unique_id: unique_installation_id });
				setState({ ...state, unique_id: unique_installation_id });
				setQRSuccess(true);
			} else {
				setQRError(true);
			}
		}
	}, [app_user]);

	const _beforeUpload = (file) => {
		console.log(file);
		toBase64(file).then((res) => {
			const qr = new Decoder();
			// qr.callback = function (err, value) {
			// 	console.log(value);

			// 	if (value) {
			// 		if (value.result === "ea60bd73-9bc8-46ab-a60f-9b734f1dab7e") {
			// 			setGenerated(true);
			// 			const generatedQR = _generateQRHandler();
			// 			form.setFieldsValue({ unique_id: generatedQR });
			// 			setState({ ...state, unique_id: generatedQR });
			// 			setQRSuccess(true);
			// 		} else {
			// 			getQR({
			// 				variables: {
			// 					unique_id: value.result,
			// 				},
			// 			});
			// 		}
			// 	} else {
			// 		setQRError(true);
			// 	}
			// };
			// // qr.decode({ width, height }, data);
			// qr.decode(res);
			qr.scan(res)
				.then((res) => {
					if (res.data === "ea60bd73-9bc8-46ab-a60f-9b734f1dab7e") {
						setGenerated(true);
						const generatedQR = _generateQRHandler();
						form.setFieldsValue({ unique_id: generatedQR });
						setState({ ...state, unique_id: generatedQR });
						setQRSuccess(true);
					} else {
						getQR({
							variables: {
								unique_id: res.data,
							},
						});
					}
				})
				.catch((err) => {
					setQRError(true);
				});
		});
	};

	const resizeImage = (file, type) => {
		if (["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				50,
				0,
				(uri) => {
					imageHandler(uri, type, file);
				},
				"blob"
			);
		} else {
			imageHandler(file, type, file);
		}
	};

	const imageHandler = async (file, type, orig) => {
		console.log(orig);

		const isJpgOrPng =
			orig.type === "image/jpeg" ||
			orig.type === "image/png" ||
			orig.type === "image/jpg" ||
			orig.type === "application/pdf";

		const isLt2M = orig.size / 1024 / 1024 <= 4;
		if (isJpgOrPng && isLt2M) {
			const picture = await toBase64(orig);
			switch (type) {
				case "medical_certificate":
					setCertificates([
						...certificates,
						{
							uid: orig.uid,
							name: orig.name,
							url: picture,
						},
					]);
					break;
				case "company_id":
					setIds([
						...ids,
						{
							uid: orig.uid,
							name: orig.name,
							url: picture,
						},
					]);
					break;
				case "certificate_of_employment":
					setEmploymentCerticates([
						...employment_certificates,
						{
							uid: orig.uid,
							name: orig.name,
							url: picture,
						},
					]);
					break;
				case "picture":
					setPictures([
						{
							uid: orig.uid,
							name: orig.name,
							url: picture,
						},
					]);
					break;
				default:
					break;
			}
			if (type === "picture") {
				const items = files.filter(({ type }) => type !== "picture");
				setFiles([...files, { file, type }]);
			} else {
				setFiles([...files, { file, type }]);
			}
		} else {
			setError(true);
		}
	};

	const _medicalCertificateHandler = async (file) => {
		resizeImage(file, "medical_certificate");
	};

	const _companyIdsHandler = async (file) => {
		resizeImage(file, "company_id");
	};

	const _certificateOfEmploymentHandler = async (file) => {
		resizeImage(file, "certificate_of_employment");
	};

	const _picturesHandler = async (file) => {
		if (["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
			resizeImage(file, "picture");
		} else {
			setError(true);
		}
	};

	const _removeImage = async (data) => {
		let items = files.filter(({ file }) => data.uid !== file.uid);
		setFiles([...items]);
		items = certificates.filter(({ uid }) => uid !== data.uid);
		setCertificates([...items]);
		items = ids.filter(({ uid }) => uid !== data.uid);
		setIds([...items]);
		items = employment_certificates.filter(({ uid }) => uid !== data.uid);
		setEmploymentCerticates([...items]);
		items = pictures.filter(({ uid }) => uid !== data.uid);
		setPictures([...items]);
	};

	const _qrHandler = () => {
		setShowQR(!showQR);
		// setGenerated(false);
	};

	const _scanHandler = (data) => {
		if (data) {
			if (data === "ea60bd73-9bc8-46ab-a60f-9b734f1dab7e") {
				setGenerated(true);
				const generatedQR = _generateQRHandler();
				form.setFieldsValue({ unique_id: generatedQR });
				setState({ ...state, unique_id: generatedQR });
				setQRSuccess(true);
			} else {
				getQR({
					variables: {
						unique_id: data,
					},
				});
			}

			setShowQR(false);
		}
	};

	const _scanErrorHandler = (err) => {
		console.log(err);
	};

	const dataURLtoBlob = (dataurl) => {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	};

	const _cameraHandler = () => {
		setShowCamera(!showCamera);
	};

	const _takePictureHandler = async () => {
		const image = await webcamRef.current.getScreenshot({
			width: 150,
			height: 150,
		});
		if (image) {
			setPicture(image);
			setShowCamera(!showCamera);
			const file = dataURLtoBlob(image);
			const url = await toBase64(file);
			setPictures([
				{
					uid: "picture",
					name: "test",
					url,
				},
			]);
			const items = files.filter(({ type }) => type !== "picture");
			setFiles([...items, { file, type: "picture" }]);
		}
	};

	const _backHandler = (step) => {
		setSteps({ ...initialSteps, [step]: true });
	};

	function getRandomStrings(length) {
		const value = "abcdef0123456789";
		const randoms = [];
		for (let i = 0; i < length; i++) {
			randoms.push(value[Math.floor(Math.random() * value.length)]);
		}
		return randoms.join("");
	}

	const values = {
		form,
		provinces,
		municipalities,
		barangays,
		destinationMunicipalities,
		destinationBarangays,
		steps,
		state,
		certificates,
		ids,
		employment_certificates,
		pictures,
		loading,
		error,
		showQR,
		showCamera,
		webcamRef,
		picture,
		success,
		qr_code,
		qr_error,
		qr_success,
		_onFinishHandler,
		_provinceChangeHandler,
		_municipalityChangeHandler,
		_destinationProvinceChangeHandler,
		_destinationMunicipalityChangeHandler,
		_onErrorHandler,
		_onSecondStepHandler,
		_onThirdStepHandler,
		_onForthStepHandler,
		_medicalCertificateHandler,
		_removeImage,
		_companyIdsHandler,
		_certificateOfEmploymentHandler,
		_picturesHandler,
		_qrHandler,
		_scanHandler,
		_scanErrorHandler,
		_cameraHandler,
		_takePictureHandler,
		_backHandler,
		_generateQRHandler,
		_beforeUpload,
		setQRSuccess,
		setQRError,
	};

	return (
		<GlobalContext.Provider value={values}>
			<RegistrationpageView />
		</GlobalContext.Provider>
	);
};

export default RegistrationpageController;
