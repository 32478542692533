import React, { useContext } from "react";
import { Row, Col, Card, Alert } from "antd";
import { GlobalContext } from "../../helpers/global-context";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import Step4 from "./step-4";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import Modal from "antd/lib/modal/Modal";

const RegistrationpageView = () => {
	const {
		steps,
		success,
		qr_code,
		qr_error,
		qr_success,
		setQRSuccess,
		setQRError,
		state,
	} = useContext(GlobalContext);

	console.log(qr_error, qr_success);

	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={6} xs={1}></Col>
				<Col lg={12} xs={22}>
					<div className="space-align-block">
						<Card
							style={{
								boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.4)",
								marginTop: 50,
								marginBottom: 50,
								paddingBottom: 50,
							}}
						>
							<Row>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22}>
									<Link to="/">
										<img
											src={require("../../assets/app-logo.png")}
											style={{ width: "100%" }}
											alt="WeTrace App"
										/>
									</Link>
								</Col>
								<Col lg={6} sm={1}></Col>
							</Row>
							<Modal
								visible={qr_error || qr_success}
								closable
								onCancel={() => {
									setQRError(false);
									setQRSuccess(false);
								}}
								onOk={() => {
									setQRError(false);
									setQRSuccess(false);
								}}
							>
								{qr_error && (
									<Alert type="error" description="INVALID QR CODE" />
								)}
								{qr_success && (
									<>
										<Alert
											type="success"
											description="QR CODE COMPLETE"
											style={{ marginBottom: 20 }}
										/>
										<div
											style={{
												display: "block",
												width: 200,
												height: 200,
												left: 0,
												right: 0,
												margin: "0 auto",
											}}
										>
											<QRCode value={state.unique_id} size={200} />
										</div>
									</>
								)}
							</Modal>
							<h1 style={{ textAlign: "center" }}>APPLICATION FORM</h1>
							<h3 style={{ textAlign: "center" }}>
								version {process.env.REACT_APP_VERSION}
							</h3>
							<Row gutter={16} style={{ marginTop: 50 }}>
								<Col lg={4} sm={1} xs={1}></Col>
								<Col lg={16} sm={22} xs={22}>
									{!success && (
										<div>
											<h2>
												Step{" "}
												{Object.keys(steps)
													.filter((step) => steps[step] === true)[0]
													.substring(6, 5)}{" "}
												of 4
											</h2>
											{steps.step_1 && <Step1 />}
											{steps.step_2 && <Step2 />}
											{steps.step_3 && <Step3 />}
											{steps.step_4 && <Step4 />}
										</div>
									)}
									{success && (
										<>
											<Alert
												message={<h3>APPLICATION COMPLETE</h3>}
												description={
													<h4>
														You have successfully submitted your application.
														Please take a picture of the{" "}
														<strong>QR CODE</strong> below and use it to check
														your application status. Click{" "}
														<Link to="/work-pass-status">HERE</Link> to check
														your application status.
													</h4>
												}
												type="success"
											/>
											<div style={{ height: 50 }} />
											<div
												style={{
													width: 260,
													height: 260,
													border: "solid 5px #ffffff",
													boxShadow: "0 0 10px #aaaaaa",
													left: 0,
													right: 0,
													margin: "0 auto",
												}}
											>
												<QRCode value={qr_code} size={250} />
											</div>
										</>
									)}
								</Col>
								<Col lg={4} sm={1} xs={1}></Col>
							</Row>
						</Card>
					</div>
				</Col>
				<Col lg={6} xs={1}></Col>
			</Row>
		</div>
	);
};

export default RegistrationpageView;
