import axios from "axios";
import { LOGIN_USER } from "../graphql/muattions";
import { print } from "graphql";

const LOGIN = async ({ username, password }) => {
	let token;
	await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(LOGIN_USER),
			variables: {
				username,
				password,
			},
		})
		.then(async (res) => {
			if (res.data.data.map_login?.token) {
				token = res.data.data.map_login.token;
			}
		})
		.catch((err) => console.log(err));
	return token;
};

export { LOGIN };
