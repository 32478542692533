import React, { useEffect, useState, useCallback } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import DashboardTemplate from "../../../template/dashboard";
import { useLazyQuery, useMutation } from "@apollo/client";
import { APPROVED } from "../../../graphql/queries";
import moment from "moment";
import { UPDATE_APPLICATION_STATUS } from "../../../graphql/muattions";
import { message } from "antd";
import ApprovedView from "./approved-view";

const ApprovedController = () => {
	const [offset, setOffset] = useState(0);
	const [dataSource, setDataSource] = useState([]);
	const [total, setTotal] = useState(0);

	const [forApproval, { data }] = useLazyQuery(APPROVED, {
		fetchPolicy: "no-cache",
	});

	const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS, {
		onCompleted(res) {
			message.success(
				`Application ${res.update_workpass_applications.returning[0].status}`
			);
			triggerQuery();
		},
	});

	const triggerQuery = useCallback(() => {
		console.log("called");
		forApproval({
			variables: {
				offset,
			},
		});
	}, [forApproval]);

	useEffect(() => {
		triggerQuery();
	}, [forApproval, offset]);

	useEffect(() => {
		if (data) {
			const items = data.workpass_applications.map((application) => {
				const {
					first_name,
					last_name,
					company_name,
					destination_barangay,
					destination_location,
					destination_municipality,
					destination_province,
					municipality_code,
					id,
					office_number,
					origin_barangay,
					origin_location,
					origin_municipality,
					origin_province,
					status,
					created_at,
					workpass_application_files,
				} = application;
				return {
					name: `${first_name} ${last_name}`,
					company: {
						company_name,
						office_number,
					},
					origin: {
						origin_barangay,
						origin_location,
						origin_municipality,
						origin_province,
					},
					destination: {
						destination_barangay,
						destination_location,
						destination_municipality,
						destination_province,
						municipality_code,
					},
					id,
					status,
					municipality_code,
					workpass_application_files,
					request_date: moment(created_at).format("LLL"),
					picture: workpass_application_files.filter(
						({ type }) => type === "picture"
					)?.[0]?.url,
				};
			});
			setDataSource(items);
			setTotal(data.workpass_applications_aggregate.aggregate.count);
		}
	}, [data]);

	const _offsetHandler = (page) => {
		setOffset((page - 1) * 5);
	};

	const _approveHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Approved",
			},
		});
	};

	const _denyHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Denied",
			},
		});
	};

	const _viewHandler = (data) => {
		console.log(data);
	};

	const values = {
		dataSource,
		offset,
		total,
		setOffset,
		_offsetHandler,
		_approveHandler,
		_denyHandler,
		_viewHandler,
	};

	return (
		<GlobalContext.Provider value={values}>
			<ApprovedView />
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(ApprovedController);
