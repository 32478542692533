import React, { useContext } from "react";
import { Form, Input, Select, Divider, Button, Row, Col } from "antd";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";

const Step2 = () => {
	const {
		_onSecondStepHandler,
		_provinceChangeHandler,
		_municipalityChangeHandler,
		_onErrorHandler,
		_backHandler,
		provinces,
		municipalities,
		barangays,
		form,
	} = useContext(GlobalContext);

	return (
		<Form
			layout="vertical"
			onFinish={_onSecondStepHandler}
			onFinishFailed={_onErrorHandler}
			form={form}
		>
			<Divider orientation="left">Origin</Divider>
			<Form.Item
				name="origin_province"
				label={<h3>Province</h3>}
				rules={VALIDATION.ORIGIN_PROVINCE}
			>
				<Select
					placeholder="Select Provincce"
					size="large"
					onChange={_provinceChangeHandler}
					showSearch={true}
				>
					{provinces.map((province, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={province.province_description}
							>
								{province.province_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="origin_municipality"
				label={<h3>Municipality</h3>}
				rules={VALIDATION.ORIGIN_MUNICIPALITY}
			>
				<Select
					placeholder="Select Municipality"
					size="large"
					onChange={_municipalityChangeHandler}
					showSearch={true}
				>
					{municipalities.map((municipality, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={municipality.city_municipality_description}
							>
								{municipality.city_municipality_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="origin_barangay"
				label={<h3>Barangay</h3>}
				rules={VALIDATION.ORIGIN_BARANGAY}
			>
				<Select
					placeholder="Select Barangay"
					size="large"
					showSearch={true}
					// onChange={_provinceChangeHandler}
				>
					{barangays.map((barangay, key) => {
						return (
							<Select.Option
								key={`${key}`}
								value={barangay.barangay_description}
							>
								{barangay.barangay_description}
							</Select.Option>
						);
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name="origin_location"
				label={<h3>Address</h3>}
				rules={VALIDATION.ORIGIN_ADDRESS}
			>
				<Input size="large" />
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					<Button
						type="primary"
						htmlType="button"
						block
						size="large"
						onClick={() => _backHandler("step_1")}
						ghost
					>
						BACK
					</Button>
				</Col>
				<Col span={12}>
					<Button type="primary" htmlType="submit" block size="large">
						NEXT
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default Step2;
